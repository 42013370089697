import Image from 'next/image'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Styled = {
  Badge: styled.div`
    color: ${({ theme }) => theme.palette.common.white};
    background-color: ${({ theme }) => theme.palette.primary.main};
    border-color: ${({ theme }) => theme.palette.primary.main};
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    float: left;
    overflow: hidden;
    border-radius: 50%;
    height: ${({ size = '48px' }) => size};
    width: ${({ size = '48px' }) => size};
    min-height: ${({ size = '48px' }) => size};
    min-width: ${({ size = '48px' }) => size};
  `,
  Image: styled(Image).attrs(() => ({
    priority: 'true',
  }))`
    height: ${({ size = '62px' }) => size};
  `,
  UserCircleIcon: styled(FontAwesomeIcon)`
    color: ${({ theme }) => theme.palette.common.white};
    margin-left: auto;
    margin-right: auto;
  `,
}

export default Styled
