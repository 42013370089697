import styled from 'styled-components'

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  ${({ theme }) => theme.palette.common.white};
  height: 64px;
`

const Branding = styled.div`
  display: flex;
  align-items: center;
  padding-left: 15px;
  > img {
    width: 173px;
  }
`

export default { Header, Branding }
