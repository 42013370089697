import { forwardRef } from 'react'

// types
import type { CustomMenuProps } from './types'

// module components
import { CustomMenuContainer, CustomMenuList } from './styles'

const CustomMenu = forwardRef<HTMLDivElement, CustomMenuProps>(
  (
    { children, 'aria-labelledby': labeledBy, style }: CustomMenuProps,
    ref
  ): JSX.Element => {
    return (
      <CustomMenuContainer ref={ref} aria-labelledby={labeledBy} style={style}>
        <CustomMenuList>{children}</CustomMenuList>
      </CustomMenuContainer>
    )
  }
)

export default CustomMenu
