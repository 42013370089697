import { useRef } from 'react'
import { useRouter } from 'next/router'
import Dropdown from 'react-bootstrap/Dropdown'

import { CustomMenu, CustomToggle } from '@/components/UI/dropdown'
import UserThumbnail from '@/components/UI/userThumbnail'
import useAuthUser from '@/utils/hooks/useAuthUser'

const UserMenu = () => {
  const { user, logout } = useAuthUser()

  const toggleRef = useRef()
  const router = useRouter()
  const handleToggleStyles = (isOpen) => {
    if (toggleRef?.current) {
      if (isOpen) toggleRef.current.classList.add('user-menu__toggle--open')
      else toggleRef.current.classList.remove('user-menu__toggle--open')
    }
  }

  return (
    <Dropdown className={'user-menu__dropdown'} onToggle={handleToggleStyles}>
      <Dropdown.Toggle
        id="dropdown-user-button"
        as={CustomToggle}
        ref={toggleRef}
        style={{ width: 'auto' }}
      >
        <UserThumbnail photo={user?.photo} variant="dark" />
      </Dropdown.Toggle>
      <Dropdown.Menu as={CustomMenu}>
        <Dropdown.Item
          onClick={() => router.push('/settings')}
          className={'user-menu__dropdown-item'}
        >
          Settings
        </Dropdown.Item>
        <Dropdown.Item onClick={logout} className={'user-menu__dropdown-item'}>
          Log Out
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

UserMenu.propTypes = {}

export default UserMenu
