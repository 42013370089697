import { forwardRef } from 'react'

// ui components
import AppNavigation from '@/components/UI/navigation/app'

// module components
import Styled from './styles'

// utils
import { useCompany } from '@/utils'

// types
import type { HeaderProps } from './types'

export const StyledHeader = forwardRef<null, HeaderProps>(
  ({ navOptions = {}, companyName }: HeaderProps, ref): JSX.Element => {
    const { company } = useCompany()
    let logoSrc = company ? `/${company}-opex.svg` : ''
    if (companyName) {
      logoSrc = `/${companyName}-opex.svg`
    }

    return (
      <div ref={ref}>
        <Styled.Header>
          <Styled.Branding>
            <img src={logoSrc} alt="OPEX standard" />
          </Styled.Branding>
          <AppNavigation {...navOptions} />
        </Styled.Header>
      </div>
    )
  }
)

export default StyledHeader
