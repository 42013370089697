import { faUserCircle } from '@fortawesome/pro-light-svg-icons'

// types
import type { UserThumbnailProps } from './types'

// module components
import Styled from './styles'

const UserThumbnail = ({
  photo,
  size = '48px',
}: UserThumbnailProps): JSX.Element => {
  return (
    <Styled.Badge size={size}>
      {photo ? (
        <Styled.Image
          src={photo}
          height={size}
          width={size}
          size={size}
          alt="Profile photo"
        />
      ) : (
        <Styled.UserCircleIcon icon={faUserCircle} size={'2x'} />
      )}
    </Styled.Badge>
  )
}

export default UserThumbnail
