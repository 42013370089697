import styled from 'styled-components'

export const CustomMenuContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: ${({ theme }) => theme.palette.gray['600']};
  color: ${({ theme }) => theme.palette.common.white};
  font-family: 'Forza Medium', Arial, sans-serif;
  z-index: 9999;
  &.show {
    display: flex;
  }
`

export const CustomMenuList = styled.ul`
  margin: 2rem 7rem 2rem 4rem;
  &:hover {
    a {
      color: ${({ theme }) => theme.palette.common.white};
      opacity: 1;
      text-decoration: underline;
      text-decoration-color: ${({ theme }) => theme.palette.primary.main};
    }
    a:not(:hover) {
      opacity: 0.5;
      text-decoration: none;
    }
  }
`

export const CustomToggleAnchor = styled.a`
  display: flex;
  align-items: center;
  flex-direction: row;

  height: 100%;
  padding: 1rem 1.75rem;
  min-width: 14rem;
  font-size: ${({ theme }) => theme.typography.fontSize.md};
  justify-content: space-evenly;
  text-decoration: none;
`
