import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Styled = {
  CompanyNav: styled.div`
    display: flex;
    align-items: center;
    height: 64px;
    justify-content: flex-end;

    .dropdown-nav-item {
      cursor: pointer;
      height: 100%;
      display: flex;
      align-items: center;
      &:hover {
        background-color: darken(
          ${({ theme }) => theme.palette.common.white},
          10%
        );
      }
      &.open {
        @extend %app-nav-dropdown;
        color: ${({ theme }) => theme.palette.common.white};
      }
      &.closed {
        @extend %app-nav-dropdown;
        color: ${({ theme }) => theme.palette.gray['900']};
      }
      & .dropdown-item {
        color: ${({ theme }) => theme.palette.common.white};
        font-size: ${({ theme }) => theme.typography.fontSize.xs};
        &:hover {
          color: darken(${({ theme }) => theme.palette.common.white}, 20%);
          background-color: ${({ theme }) => theme.palette.gray['600']};
        }
      }
    }
  `,
  NavItem: styled.p`
    margin: 0;
    padding: 0;
    font-size: ${({ theme }) => theme.typography.fontSize.xs};
    font-family: ${({ theme }) => theme.typography.fontFamily.forzaMedium};
    &.open {
      color: ${({ theme }) => theme.palette.common.white};
    }
    &.closed {
      color: ${({ theme }) => theme.palette.common.black};
    }
  `,
  Icon: styled(FontAwesomeIcon)`
    &.open {
      color: ${({ theme }) => theme.palette.common.white};
    }
    &.closed {
      color: ${({ theme }) => theme.palette.common.black};
    }
  `,
  UserBadgeWrapper: styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    &:hover {
      background-color: darken(
        ${({ theme }) => theme.palette.common.white},
        10%
      );
    }
  `,
}

export default Styled
