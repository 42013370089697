import { forwardRef } from 'react'

// types
import type { CustomToggleProps } from './types'

// module components
import { CustomToggleAnchor } from './styles'

// Dropdown needs access to the DOM node in order to position the Menu
// eslint-disable-next-line react/prop-types
export const CustomToggle = forwardRef<HTMLAnchorElement, CustomToggleProps>(
  ({ children, onClick }: CustomToggleProps, ref): JSX.Element => (
    <CustomToggleAnchor
      ref={ref}
      onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}
    >
      {children}
    </CustomToggleAnchor>
  )
)

export default CustomToggle
